import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  dateCreated: string
  image?: ImageProps
  pax?: string
  size?: string
  title: string
  URL: string
}

export const Room = memo(function Room({
  className,
  image,
  pax,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container className={className} to={URL}>
      {image ? <Image {...image} /> : null}
      <Wrapper dial={4}>
        <Title>{title}</Title>
        {pax || size ? (
          <Info dial={4} row wrap>
            {pax ? (
              <Pax dial={4} row>
                {pax}
              </Pax>
            ) : null}
            {size ? (
              <Size dial={4} row>
                {size}
              </Size>
            ) : null}
          </Info>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  height: 25.417vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  &:hover {
    &:before {
      opacity: 0.6;
    }
    img {
      transform: scale(1.1);
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    padding-bottom: 87%;
  }
`

const Wrapper = styled(FlexBox)`
  padding: 3.1875rem 3.125vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media (max-width: 1023px) {
    padding: 1.9375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 0.875rem;
  text-transform: uppercase;

  > div {
    &:nth-of-type(2) {
      margin-left: 0.625rem;
      &:before {
        content: '';
        width: 0.375rem;
        height: 0.375rem;
        background: ${({ theme }) => theme.colors.variants.primaryLight1};
        margin-right: 0.625rem;
        transform: rotate(45deg);
      }
    }
  }
`

const Pax = styled(FlexBox)``

const Size = styled(FlexBox)``
